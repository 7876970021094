#cancha.form-9 .jugador.j00 {
    top: 58.0000001%;
    left: 37.599999999%;
}
#cancha.form-9 .jugador.j01 {
    top: 58.0000001%;
    left: 68.00001%;
}
#cancha.form-9 .jugador.j02 {
    top: 2.0000001%;
    left: 7.00001%;

}
#cancha.form-9 .jugador.j03 {
    top: 2.0000001%;
    left: 37.599999999%;
}
#cancha.form-9 .jugador.j04 {
    top: 2.0000001%;
    left: 68.00001%;
}
#cancha.form-9 .jugador.j05 {
    top: 30.0000001%;
    left: 7.00001%
}
#cancha.form-9 .jugador.j06 {
    top: 30.0000001%;
    left: 37.599999999%
}
#cancha.form-9 .jugador.j07 {
    top: 30.0000001%;
    left: 68.00001%
}
#cancha.form-9 .jugador.j08 {
    top: 58.0000001%;
    left: 7.00001%
}
#cancha.form-9 .jugador.j09 {
    top: 58.0000001%;
    left: 37.599999999%
}
#cancha.form-9 .jugador.j10 {
    top: 58.0000001%;
    left: 68.00001%
}

@media screen and (max-width:992px) {
    #cancha.form-9 .jugador.j00 {
        top: 58.0000001%;
        left: 37.599999999%;
    }
    #cancha.form-9 .jugador.j01 {
        top: 58.0000001%;
        left: 68.00001%;
    }
    #cancha.form-9 .jugador.j02 {
        top: 2.0000001%;
        left: 7.00001%;
    
    }
    #cancha.form-9 .jugador.j03 {
        top: 2.0000001%;
        left: 37.599999999%;
    }
    #cancha.form-9 .jugador.j04 {
        top: 2.0000001%;
        left: 68.00001%;
    }
    #cancha.form-9 .jugador.j05 {
        top: 30.0000001%;
        left: 7.00001%
    }
    #cancha.form-9 .jugador.j06 {
        top: 30.0000001%;
        left: 37.599999999%
    }
    #cancha.form-9 .jugador.j07 {
        top: 30.0000001%;
        left: 68.00001%
    }
    #cancha.form-9 .jugador.j08 {
        top: 58.0000001%;
        left: 7.00001%
    }
    #cancha.form-9 .jugador.j09 {
        top: 58.0000001%;
        left: 37.599999999%
    }
    #cancha.form-9 .jugador.j10 {
        top: 58.0000001%;
        left: 68.00001%
    }
}
@media screen and (max-width:767px) {
    #cancha.form-9 .jugador.j00 {
        top: 58.0000001%;
        left: 37.599999999%;
    }
    #cancha.form-9 .jugador.j01 {
        top: 58.0000001%;
        left: 68.00001%;
    }
    #cancha.form-9 .jugador.j02 {
        top: 2.0000001%;
        left: 7.00001%;
    
    }
    #cancha.form-9 .jugador.j03 {
        top: 2.0000001%;
        left: 37.599999999%;
    }
    #cancha.form-9 .jugador.j04 {
        top: 2.0000001%;
        left: 68.00001%;
    }
    #cancha.form-9 .jugador.j05 {
        top: 30.0000001%;
        left: 7.00001%
    }
    #cancha.form-9 .jugador.j06 {
        top: 30.0000001%;
        left: 37.599999999%
    }
    #cancha.form-9 .jugador.j07 {
        top: 30.0000001%;
        left: 68.00001%
    }
    #cancha.form-9 .jugador.j08 {
        top: 58.0000001%;
        left: 7.00001%
    }
    #cancha.form-9 .jugador.j09 {
        top: 58.0000001%;
        left: 37.599999999%
    }
    #cancha.form-9 .jugador.j10 {
        top: 58.0000001%;
        left: 68.00001%
    }
}
@media screen and (max-width:480px) {
    #cancha.form-9 .jugador.j00 {
        top: 58.0000001%;
        left: 37.599999999%;
    }
    #cancha.form-9 .jugador.j01 {
        top: 58.0000001%;
        left: 68.00001%;
    }
    #cancha.form-9 .jugador.j02 {
        top: 2.0000001%;
        left: 7.00001%;
    
    }
    #cancha.form-9 .jugador.j03 {
        top: 2.0000001%;
        left: 37.599999999%;
    }
    #cancha.form-9 .jugador.j04 {
        top: 2.0000001%;
        left: 68.00001%;
    }
    #cancha.form-9 .jugador.j05 {
        top: 30.0000001%;
        left: 7.00001%
    }
    #cancha.form-9 .jugador.j06 {
        top: 30.0000001%;
        left: 37.599999999%
    }
    #cancha.form-9 .jugador.j07 {
        top: 30.0000001%;
        left: 68.00001%
    }
    #cancha.form-9 .jugador.j08 {
        top: 58.0000001%;
        left: 7.00001%
    }
    #cancha.form-9 .jugador.j09 {
        top: 58.0000001%;
        left: 37.599999999%
    }
    #cancha.form-9 .jugador.j10 {
        top: 58.0000001%;
        left: 68.00001%
    }
}