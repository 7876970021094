.cancha-foot {
	margin-top:180px
}
.suplentes {
	position: absolute;
	top: 675px;
	left: 400px;
}
#suplentes {
	position: absolute;
	top: 705px
}
#suplentes .jugador.s01 {
	left: 80px
}
#suplentes .jugador.s02 {
	top: 60px;
	left: 180px
}
#suplentes .jugador.s03 {
	left: 280px
}
#suplentes .jugador.s04 {
	top: 60px;
	left: 380px
}
#suplentes .jugador.s05 {
	left: 480px
}
#suplentes .jugador.s06 {
	top: 60px;
	left: 580px
}
#suplentes .jugador.s07 {
	left: 680px
}
.publicidad-btn {
	/* background-image:url(../images/fence.png); */
	background-size:90px;
	position:absolute;
	left:0;
	right:0;
	bottom:0;
	padding:5px;
}
.publicidad-btn img {
	padding:5px;
	height: 42px;
}
.adboard {
	padding:5px 0;
	display:block;
	margin:10px;
	text-align: center;
	border:2px solid #444;
}
@media screen and (max-width:992px) {
	.suplentes {
		top: 550px;
		left: 305px;
	}
	#suplentes {
		top: 580px
	}
	#suplentes .jugador.s01 {
		left: -10px
	}
	#suplentes .jugador.s02 {
		top: 60px;
		left: 90px
	}
	#suplentes .jugador.s03 {
		left: 190px
	}
	#suplentes .jugador.s04 {
		top: 60px;
		left: 290px
	}
	#suplentes .jugador.s05 {
		left: 390px
	}
	#suplentes .jugador.s06 {
		top: 60px;
		left: 490px
	}
	#suplentes .jugador.s07 {
		left: 590px
	}
}
@media screen and (max-width:767px) {
	.publicidad-btn {
		background-image:none;
		bottom: -57px;
	}
	.publicidad-btn img {
		max-width: 100%;
		max-height: 100%;
	}
	.row {
		margin-left: -5px;
		margin-right: -5px;
	}
	.col-xs-4 {
		padding:0;
	}
	.adboard {
		padding:5px 0;
		margin:0;
		border:none;
	}
	.cancha-foot {
		margin-top:370px
	}
	.suplentes {
		top: 740px;
		left: 180px;
	}
	#suplentes {
		top: 780px
	}
	#suplentes .jugador.s01 {
		top: 0;
		left: 82px
	}
	#suplentes .jugador.s02 {
		top: 0;
		left: 247px
	}
	#suplentes .jugador.s03 {
		top: 90px;
		left: 0px
	}
	#suplentes .jugador.s04 {
		top: 90px;
		left: 165px
	}
	#suplentes .jugador.s05 {
		top: 90px;
		left: 330px
	}
	#suplentes .jugador.s06 {
		top: 180px;
		left: 82px
	}
	#suplentes .jugador.s07 {
		top: 180px;
		left: 247px
	}
}
@media screen and (max-width:480px) {
	.publicidad-btn {
		bottom:-58px;
	}
	.cancha-foot {
		margin-top:270px
	}
	.suplentes {
		top: 460px;
		left: 95px;
	}
	#suplentes {
		top: 500px
	}
	#suplentes .jugador.s01 {
		top: 0;
		left: 50px
	}
	#suplentes .jugador.s02 {
		top: 0;
		left: 152px
	}
	#suplentes .jugador.s03 {
		top: 60px;
		left: 0px
	}
	#suplentes .jugador.s04 {
		top: 60px;
		left: 102px
	}
	#suplentes .jugador.s05 {
		top: 60px;
		left: 204px
	}
	#suplentes .jugador.s06 {
		top: 120px;
		left: 50px
	}
	#suplentes .jugador.s07 {
		top: 120px;
		left: 152px
	}
}